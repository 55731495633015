import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'
import HomeProductLine from "../components/product/product-line";
import ProductMain from "../components/product/product-main";
import QualityAssurance from "../components/assurance/quality-assurance";
import CooperativePartner from "../components/partner/partner";
import Form from "../components/form";
import Banner from "../components/banner/banner";
import {convertFactory} from "../utils/convert";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {Factory} from "../components/factory";
import SEO from "../components/seo";

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const bannerNodes = get(this, 'props.data.allContentfulBanner.edges')
    const productLineNodes = get(this, 'props.data.allContentfulProductLine.edges')
    const allFacory = get(this, 'props.data.allContentfulFacory.nodes')
    const partnerNodes = get(this, 'props.data.allContentfulPartner.edges')
    const allAssurance = get(this, 'props.data.allContentfulAssurance.nodes')
    const allKeywords = get(this, 'props.data.allContentfulPageKeywords.nodes')
    const homeFullCustomizable = get(this, 'props.data.homeFullCustomizable')
    const homeCommitment = get(this, 'props.data.homeCommitment')
    const banners = bannerNodes.map(x => x.node)
    const lines = productLineNodes.map(x => x.node)
    const partners = partnerNodes.map(x => x.node)
    const allFactory = get(this, 'props.data.allContentfulFacory.nodes')

    return (
      <Layout location={this.props.location}>
        <div style={{background: '#fff'}}>
          <SEO title={'Home'} keywords={allKeywords[0]?.keywords}/>
          <div>
            <Banner banners={banners}/>
          </div>
          <div className="mt-5 mb-5">
            <HomeProductLine title={'خط الإنتاج'} lines={lines}/>
          </div>
          <div className="mb-5 w-75 container">
            <ProductMain title={'المنتج الرئيسي'} lines={lines}/>
          </div>
          <div className="mb-5">
            <Factory factories={allFactory}/>
          </div>
          <div className="container mb-5">
            <div>
              <h2 className="text-center p-4">قابل للتخصيص بالكامل</h2>
              <div className="card border-0">
                <div className="row g-0">
                  <div className="col-md-5">
                    <div className="card-body">
                      <h3 className="card-title">تخصيص الوحدة</h3>
                      <p className="card-text pt-2">
                        من أجل تلبية احتياجات السوق المتنوعة ، نقدم منتجات وخدمات مخصصة للعملاء في سنغافورة واليابان والمملكة العربية السعودية ودول أخرى. لدينا مزايا النظام الناضج والتكلفة المنخفضة والخبرة الغنية. بصفتنا شركة تصنيع وتصدير متكاملة واسعة النطاق، فقد نجحنا في اجتياز عمليات تدقيق أنظمة العملاء في الصين، وكينيا، و مكتب كينيا للمعايير، ودبي، و إدارة الجودة البيئية، وعلامة الجودة السعودية، وغيرها من عمليات تدقيق أنظمة العملاء في البلدان الأخرى في العشرين عامًا الماضية.
                      </p>
                      <div>
                        <div className="learn-more">
                          <AniLink to={`/contact-us`} duration={0.3} hex="#9dc428" paintDrip>
                            أعلم المزيد
                          </AniLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <img src={homeFullCustomizable.file.url} alt={homeFullCustomizable.title}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 w-75 container">
            <QualityAssurance title={'ضمان الجودة'} assurances={allAssurance}/>
          </div>
          <div className="container">
            <div className="card mb-5 border-0">
              <div className="row g-0">
                <div className="col-md-7">
                  <img src={homeCommitment.file.url} alt={homeCommitment.title}/>
                </div>
                <div className="col-md-5">
                  <div className="card-body">
                    <h3 className="card-title">التزامنا تجاهك</h3>
                    <p className="card-text pt-2"><small className="text-muted">لقد عملنا بكل فخر مع هؤلاء المبتكرين لتسريع تقدم إطلاق منتجاتهم</small></p>
                    <ul className="pt-2">
                      <li>
                        تعديلات غير محدودة قبل الإنتاج الضخم
                      </li>
                      <li>
                        دعم فني محترف
                      </li>
                      <li>
                        ضمان لمدة عامين
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="contact-us" className="mb-5">
            <Form title={'اتصل بنا'} lang={'ar'}/>
          </div>
          <div>
            <CooperativePartner title={"العلامة التجارية المشتركة"} partners={partners}/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeARQuery {
    allContentfulProductLine(sort: { fields: [name], order: DESC }, filter: {node_locale: {eq: "ar"}}) {
      edges {
        node {
          identity
          name
          imageLink {
            file{
              url
            }
          }
          products {
            id
            name
            price
            link
            minOrder
            imageLink {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulBanner(sort: { fields: [name], order: DESC }, filter: {node_locale: {eq: "ar"}}) {
      edges {
        node {
          name
          imageLink {
            title
            file {
              url
            }
          }
          type
        }
      }
    }
    allContentfulAssurance(filter: {node_locale: {eq: "ar"}}) {
      nodes {
        id
        name
        imageLink {
          title
          fluid(maxWidth: 1440, quality: 100) {
            src
          }
        }
      }
    }
    allContentfulPartner(sort: { fields: [name], order: DESC }, filter: {node_locale: {eq: "ar"}}) {
      edges {
        node {
          name
          logo {
            fluid {
              src
            }
          }
        }
      }
    }
    allContentfulPageKeywords(filter: {page: {eq: "home"}, node_locale: {eq: "ar"}}) {
        nodes {
            keywords
            node_locale
            page
        }
    }
    allContentfulFacory(filter: {node_locale: {eq: "ar"}}) {
      nodes {
        id
        imageLink {
          id
          title
          file {
              url
          }
        }
        introduction {
          introduction
        }
        name
      }
    }
    homeCommitment: contentfulAsset(title: {eq: "home_commitment"}) {
      title
      file {
        url
      }
    }
    homeFullCustomizable: contentfulAsset(title: {eq: "home_full_customizable"}) {
      title
      file {
        url
      }
    }
  }
`
